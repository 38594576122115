import Vue from 'vue'
import VueRouter from 'vue-router'
import Index from '../views/Index.vue'
import Login from '../views/Login.vue'
import NotFound from '../views/404.vue'
import Home from '../views/Home.vue'
import InfoShow from '../views/InfoShow.vue'
import FundList from '../views/FundList.vue'
import Permission from '../views/Permission.vue'
import Information from '../views/Information.vue'
import Equipment from '../views/Equipment.vue'
import Forget from '../views/Forget.vue'
import Log from '../views/Log.vue'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect:'/login'
  }, 
  {
    path: '/index',
    name:'index',
    component: Index,
    children:[{
      path:'',
      component:Home
    },{
      path:'/home',
      name:"home",
      component:Home
    },{
      path:'/Infoshow',
      name:"Infoshow",
      component:InfoShow
    },{
      path:'/fundlist',
      name:"fundlist",
      component:FundList
    },{
      path: '/permission',
      name:'permission',
      component: Permission
    },{
      path: '/information',
      name:'information',
      component: Information
    },{
      path: '/log',
      name:'log',
      component: Log
    },{
      path: '/equipment',
      name:'equipment',
      component: Equipment
    }]
  }, 
  {
    path: '/login',
    name:'login',
    component: Login
  },
  {
    path: '/forget',
    name:'forget',
    component: Forget
  },
  {
    path: '*',
    name:'/404',
    component: NotFound
  },
]

const router = new VueRouter({
  // mode: 'history',
  mode:'hash',
  base: process.env.BASE_URL,
  routes
})
//路由守卫
router.beforeEach((to,from,next)=>{
  const isLogin=localStorage.eleToken ? true:false;
  if(to.path=='/login'||'forget'){
    next();
  }
  else{
    isLogin? next():next('/login')
  }
})

export default router
