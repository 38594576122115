import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

//用于显示vue-detools
const types={
  SET_AUTHENTICATED:'SET_AUTHENTICATED',
  SET_USER:'SET_USER',
  SET_USERINFO:'SET_USERINFO'
}

const state={
  isAuthenticated:false,//身份token
  user:{},
  userinfo:{}
}
const getters={//获取isAu,user
  isAuthenticated:state=>state.isAuthenticated,
  user:state=>state.user,
  userinfo:state=>state.userinfo
}
const mutations={//同步更改状态信息
  //设置是否授权
  [types.SET_AUTHENTICATED](state,isAuthenticated){
    if(isAuthenticated) state.isAuthenticated = isAuthenticated
    else state.isAuthenticated = false
  },
  //是否有user
  [types.SET_USER](state,user){
    if(user) state.user = user
    else state.user = {}
  },
  //是否有userinfo
  [types.SET_USERINFO](state,userinfo){
    if(userinfo) state.userinfo = userinfo
    else state.userinfo = {}
  }
}
const actions={//异步执行便于调试工具追踪变化
  //无非就是调用mountains
  setAuthenticated:({ commit },isAuthenticated)=>{
    commit(types.SET_AUTHENTICATED,isAuthenticated)
  },
  setUser:({ commit },user)=>{
    commit(types.SET_USER,user)
  },
  //点击退出时调用此方法退出登录
  clearCurrentState:({ commit })=>{
    commit(types.SET_AUTHENTICATED,false)
    commit(types.SET_USER,null)
  },
  setUserInfo:({ commit },userinfo)=>{
    commit(types.SET_USERINFO,userinfo)
  }
}

export default new Vuex.Store({
  state,
  getters,
  mutations,
  actions,
})
