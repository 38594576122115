import axios from "axios";
import { Loading, Message } from "element-ui";
import router from "./router";
const request = axios.create({
  baseURL: "http://47.97.4.241:3000/",
  timeout: 10000,
});
let loading;

function startLoading() {
  loading = Loading.service({
    lock: true,
    text: "拼命加载中....",
    background: "rgba(0,0,0,0,7)",
  });
}

function endLoading() {
  loading.close();
}
//请求拦截
request.interceptors.request.use(
  (config) => {
    //加载动画
    startLoading();
    if(localStorage.eleToken){
      //设置为同一请求头
      config.headers.Authorization=localStorage.eleToken;
  }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

//响应拦截
request.interceptors.response.use(
  (response) => {
    endLoading();
    if (response.status == 200) {
      return response;
    }
    if (response.status == 401) {
      localStorage.removeItem("eleToken");
      //跳转到登录界面
     return router.push("/login");
    }
  },
  (error) => {
    endLoading(); //停止加载动画
    Message.error(error.response.data);

    //获取错误状态码
    const { status } = error.response;
    console.log(status)
    if (status == 401) {
      Message.error("token失效,请重新登录！");
      //清除token
      localStorage.removeItem("eleToken");

      //跳转到登录界面
      router.push("/login");
    }

    return Promise.reject(error);
  }
);

request.get = (options) =>
  request({
    method: "get",
    ...options,
  });
request.post = (options) =>
  request({
    method: "post",
    ...options,
  });

export default request;
