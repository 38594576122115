 <template>
    <div class="fillContainer">
        <div> 
            <el-form :inline="true" ref="add_data" :model='search_data'>
                <el-form-item label="按照时间筛选:">
                    <el-date-picker
                    v-model="search_data.startTime"
                    type="datetime"
                    placeholder="选择开始时间"
                    >
                    </el-date-picker>
                    --
                    <el-date-picker
                    v-model="search_data.endTime"
                    type="datetime"
                    placeholder="选择结束时间"
                    >
                    </el-date-picker>
                </el-form-item>
                <el-form-item>
                    <el-button type='primary' size="search" @click="handleSearch()">筛选</el-button>
                </el-form-item>
                <el-form-item class="btnRight">
                    <el-form-item  label="按照账号筛选:">
                        <el-input v-model="search_data.id" placeholder="请输入账号" style="dispaly:inline"></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button type='primary' size="search" @click="search()">搜索</el-button>
                    </el-form-item>
                </el-form-item>
            </el-form>
        </div>
        <div class="table_container">
            <el-table
            v-if="tableData.length > 0"
            :data="tableData"
            max-height='450'
            border
            style="width: 100%">
            <el-table-column
                type='index'
                label="序号"
                align='center'
                width="50">
                </el-table-column>
                <el-table-column
                prop='registertime'
                label="创建时间"
                align='center'
                width="250">
                <template slot-scope="scope">
                    <i class="el-icon-time"></i>
                    <span style="margin-left: 10px">{{ scope.row.registertime}}</span>
                </template>
                </el-table-column>
                <el-table-column
                prop='Id'
                label="账号"
                align='center'
                width="170">
                </el-table-column>
                <el-table-column
                prop='username'
                label="姓名"
                align='center'
                width="150">
                </el-table-column>
                <el-table-column
                prop='email'
                label="邮箱"
                align='center'
                width="170">
                <template slot-scope="scope">
                    <span>{{ scope.row.email }}</span>
                </template>
                </el-table-column>
                <el-table-column
                prop='phone'
                label="联系方式"
                align='center'
                width="170">
                <template slot-scope="scope">
                    <span style="color:#4db3ff">{{ scope.row.phone }}</span>
                </template>
                </el-table-column>
                <el-table-column
                prop='gender'
                label="性别"
                align='center'
                width="70">
                </el-table-column>
                <el-table-column label="操作"  prop="operation" fixed="right" align="center" width="280">
                    <template slot-scope="scope">
                        <el-button
                        type='warning'
                        icon='edit'
                        size="small"
                        @click="handleEdit(scope.$index, scope.row)">编辑</el-button>
                        <el-button
                        size="small"
                        icon='success'
                        type="info"
                        @click="handleAddadmin(scope.$index,scope.row)">升级</el-button>
                        <el-button
                        size="small"
                        icon='delete'
                        type="danger"
                        @click="handleDelete(scope.$index, scope.row)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-row>
                <el-col :span='24'>
                    <div class="pagination">
                        <el-pagination
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :current-page.sync="paginations.page_index"
                        :page-sizes="paginations.page_sizes"
                        :page-size="paginations.page_size"
                        :layout="paginations.layout"
                        :total="paginations.total">
                        </el-pagination>
                    </div>
                </el-col>
            </el-row>
        </div>
        <Dialog :dialog="dialog" :formData="formData" @update='getProfile'></Dialog>    
    </div>    
</template>

<script>
import Dialog from '../components/Dialoguser'

export default {
    name:'fundList',
    data(){
        return {
            search_data:{
                startTime:'',
                endTime:'',
                id:''
            },
            paginations:{
                page_index:1, //当前位于多少页
                total:0, //总数
                page_size:5,  //一页显示多少条
                page_sizes:[5,10,15], //每页显示多少条
                layout:'total,sizes,prev,pager,next,jumper' // 翻页属性
            },
            tableData:[],
            allTableData:[],
            fileterTableData:[],
            formData:{
                registertime:'',
                id:'',
                username:'',
                email:'',
                phone:'',
                gender:''
            },
            dialog:{
                show:false,
                title:'',
                option:'edit'
            }
        }
    },
    methods:{
        getProfile(){
            //首先获取信息
            this.$axios.get({
                url:"/my/admin/userlist",
            })
            .then(res=>{
                this.allTableData = res.data.data
                this.fileterTableData = res.data.data
                //设置分页数据
                this.setPaginations()
            })
            .catch(err=>console.log(err))
        },
        handleEdit(index,row){
            this.dialog = {
                show:true,
                title:'编辑用户信息',
                option:'exit'
            }
            this.formData = {
                registertime:row.registertime,
                id:row.Id,
                username:row.username,
                email:row.email,
                phone:row.phone,
                gender:row.gender
            }
        },
        handleDelete(index,row){
            this.$axios.get({url:`/my/admin/delete/${row.Id}`,data:this.Id}).then(()=>{
                this.$message('删除成功')
                this.getProfile()
            }).catch(error=>console.log(error))
        },
        handleAddadmin(index,row){
            this.$axios.get({url:`/my/admin/addadmin/${row.Id}`,data:this.Id}).then(()=>{
                this.$message('已成为管理员')
                this.getProfile()
            }).catch(error=>console.log(error))
        },
        setPaginations(){
            this.paginations.total = this.allTableData.length
            this.paginations.page_index = 1
            this.paginations.page_size = 5
            //设置默认分页数据
            this.tableData = this.allTableData.filter((item,index)=>{
                return index < this.paginations.page_size
            })
        },
        handleSizeChange(page_size){
            this.paginations.page_index = 1
            this.paginations.page_size = page_size
            this.tableData = this.allTableData.filter((item,index)=>{
                return index < page_size
            })
        },
        handleCurrentChange(page){
            //获取当前页
            let index = this.paginations.page_size * (page-1)
            //数据总数
            let nums = this.paginations.page_size * page
            let tables = []
            for(let i=index; i<nums; i++){
                if(this.allTableData[i]){
                    tables.push(this.allTableData[i])
                }
                this.tableData = tables
            }
        },
        handleSearch(){
            if(!this.search_data.startTime || !this.search_data.endTime){
                this.$message({
                    type:'warning',
                    message:'请选择时间区间'
                })
                this.getProfile()
                return
            }
            const sTime = this.search_data.startTime.getTime()
            const eTime = this.search_data.endTime.getTime()
            this.allTableData = this.fileterTableData.filter(item=>{
                let date = new Date(item.registertime)
                let time = date.getTime()
                console.log(time)
                return time >= sTime && time <= eTime//在时间区间
            })
            this.setPaginations()
        },
        search(){
             if(!this.search_data.id){
                 this.getProfile()
                this.$message({
                    type:'warning',
                    message:'请输入查询账号'
                })
                return
            }
            this.allTableData = this.fileterTableData.filter(item=>{
                return item.Id==this.search_data.id
            })
            this.setPaginations()
        }
    },
    computed:{
        user(){
            return this.$store.getters.user
        }
    },
    created(){
        this.getProfile()
    },
    components:{
        Dialog,
    }
}
</script>

<style scoped>
.fillContainer{
    width: 100%;
    height: 100%;
    padding: 16px;
    box-sizing: border-box
}

.btnRight{
    float: right;
}
.textRirht{
    float: right;
}

.pagination{
    text-align: right;
    margin-top: 10px;
}
</style>
 