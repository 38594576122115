<template>
    <div class="infoshow">
        <el-row type='flex' class="row-bg" justify='center'>
            <el-col :span='8'>
                 <div class="user"> 
                    <div class="user-item">
                        <i class="iconfont icon-yonghu"></i>   
                        <span>姓名：{{userinfo.username}}</span>          
                        <span>账号：{{userinfo.Id}}</span>
                        <!-- <div class="changep"><el-button type="primary"  @click="open" round>修改密码</el-button></div> -->
                    </div>
                </div>
            </el-col>
            <el-col :span='16'>
                <div class="userinfo">
                    <div class="user-item">
                      <el-form class="user-account-key" ref="form" :model="form" :rules="rules" label-width="100px">
                          <el-form-item label="原密码" prop="oldPwd">
                              <el-input type="password" placeholder="请输入原密码" v-model="form.oldPwd"></el-input>
                          </el-form-item>
                          <el-form-item label="新密码" prop="newPwd">
                              <el-input type="password" placeholder="请设置新密码" v-model="form.newPwd"></el-input>
                          </el-form-item>
                          <el-form-item label="确认密码" prop="newPwd2">
                              <el-input type="password" placeholder="请确认新密码" v-model="form.newPwd2"></el-input>
                          </el-form-item>
                          <el-form-item>
                              <el-button type="primary"  @click="onSubmit('form')">保存</el-button>
                              <el-button @click="$refs['form'].resetFields()">重置</el-button>
                          </el-form-item>
                      </el-form>
                    </div>
                </div>
            </el-col>
        </el-row>
    </div>
</template>
<script>
    export default {
        data() {
            let validatenewPwd = (rule, value, callback) => {
                if (value === this.form.oldPwd) {
                    callback(new Error('新密码不能与原密码相同!'))
                } else {
                    callback()
                }
            }
            let validatenewPwd2 = (rule, value, callback) => {
                if (value !== this.form.newPwd) {
                    callback(new Error('与新密码不一致!'))
                } else {
                    callback()
                }
            }
            return {
                form: {
                oldPwd:'',
                newPwd:''
            },
                rules: {
                    oldPwd: [
                        {required: true, message: '请输入原密码', trigger: 'blur'}
                    ],
                    newPwd: [
                        {required: true, message: '请设置新密码', trigger: 'blur'},
                        {validator: validatenewPwd, trigger: 'blur'}
                    ]
                    ,
                    newPwd2: [
                        {required: true, message: '请确认新密码', trigger: 'blur'},
                        {validator: validatenewPwd2, trigger: 'blur'}
                    ]
                }
            }
        },
        methods: {
            loading(){
                this.$axios.get({
                url:"/my/userinfo",
             // data:localStorage.getItem('eleToken'),
            }).then(res=>{
            const { data } = res.data.data
            //data存储到VueX中
            this.$store.dispatch("setUserInfo",res.data.data)
            })
        },
        
          open() {
                
            },
            onSubmit(formName) {
                this.$refs[formName].validate(valid => 
                {
                            if (valid) {
                this.$axios.post({
                    url:"/my/updatepwd",
                    data:this.form,
                   })
                        .then(res=>{
                            if(res.data.status==0)
                            this.$message.success(res.data.message)
                            else
                            this.$message.info(res.data.message)
                        })
                }                   
            })
            }
        },
        mounted:function () {   //自动触发写入的函数
            this.loading();
            this.changepassword();
        },
        computed:{
        userinfo(){
            return this.$store.getters.userinfo
        }
    }
    }
</script>

<style scoped>
.infoshow {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  /* padding: 16px; */
}
.row-bg {
  width: 100%;
  height: 100%;
}
.user {
  text-align: center;
  position: relative;
  top: 30%;
}
.user span {
  display: block;
  text-align: center;
  margin-top: 20px;
  font-size: 20px;
  font-weight: bold;
}
.userinfo {
  height: 100%;
  background-color: #eee;
}
.user-item {
  position: relative;
  top: 30%;
  /* padding: 26px; */
  font-size: 28px;
  color: #333;
}
.user-account-key {
    width: 500px;
    margin: 0 auto;
    }
.changep {
    padding-top: 20px;
}
</style>


