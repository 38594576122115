<template>
    <div class="login">      
        <section class="form_container">
            <div class="manage_tip">
                <span class="title">LoRa智慧开关系统</span>
                <el-form :model="loginUser" :rules="rules" ref="loginForm" label-width="60px" class="loginForm">
                     <el-form-item label="账号" prop="UserId">
                        <el-input v-model="loginUser.UserId" placeholder="请输入账号"></el-input>
                    </el-form-item>
                    <el-form-item label="密码" prop="Password">
                        <el-input type='password' v-model="loginUser.Password" placeholder="请输入密码"></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button type='primary' class="submit_btn" @click="submitForm('loginForm')">登录</el-button>
                    </el-form-item>
                    <el-link class="right" @click="forget">忘记密码</el-link>
                </el-form>
            </div>
        </section>
        <div class="copyright">
				  <div class="bgc" align="center" style="color: #ffffff;">
							<div class="bgcc">
								<p style=" max-width: 1600px;">开封市淳阳科技有限责任公司 ©[PURESUN]&nbsp;&nbsp;2018-{{ nowDate }}</p>
                                <p style=" max-width: 1600px;">豫ICP备16018699-3&nbsp;&nbsp;&nbsp;<a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=41010802002001"><img src="bnico.png">&nbsp;豫公网安备 41010802002001号</a></p>
							</div>
				 </div>
			 </div> 
    </div>
</template>

<script>
import jwt_decode from 'jwt-decode'

export default {
    name:'login',
    data(){
        return{
            nowDate: "",
            loginUser:{
                UserId:'',
                Password:'',
            },
            rules:{
                UserId:[
                    {required:true,message:'密码不能为空',trigger:'blur'},
                    {min:6,max:11,message:'长度应在6-11之间',trigger:'blur'}
                ],
                Password:[
                    {required:true,message:'密码不能为空',trigger:'blur'},
                    {min:6,max:30,message:'长度在6-30之间',trigger:'blur'}
                ],
            }
        }
    },
    methods:{
        formData(paramObj) {
        if (!paramObj) {
            paramObj = {};
        }
        let data = new FormData();
        Object.keys(paramObj).forEach((key) => data.append(key, paramObj[key]));
        return data;
    },
        submitForm(formName){
            this.$refs[formName].validate((valid) => {
                            if (valid) {
                this.$axios.post({
                    url:"/api/login",
                    data:this.loginUser,
                   })
                        .then(res=>{
                        
                            //登录成功，拿到token
                            const { token } = res.data;
                            // console.log(token)
                            // console.log(res)
                            localStorage.setItem('eleToken',token)
                            //解析token
                            const decode = jwt_decode(token)
                            // console.log(decode)
                            //token存储到VueX中
                            this.$store.dispatch("setAuthenticated",!this.isEmpty(decode))
                            this.$store.dispatch("setUser",decode)
                            this.$router.push('/index')
                        })
                }  
                  
            })
        },
        forget(){
            this.$router.push('/forget')
        },
        isEmpty(value){
            return (
                value === undefined ||
                value === null ||
                (typeof value === 'object' && Object.keys(value).length===0) ||
                (typeof value === 'string' && value.trim().length===0)
            );
        },
        currentTime() {
      setInterval(this.formatDate, 500);
    },
    formatDate() {
      let date = new Date();
      let year = date.getFullYear(); // 年
      this.nowDate = `${year}`;
        }
    },
     mounted() {
    this.currentTime();
  },
}
</script>

<style scoped>
.login {
  position: fixed;
  width: 100%;
  height: 100%;
  background: url(../assets/clock.jpeg) no-repeat center center;
  background-size: 100% 100%;
}
.form_container {
  width: 370px;
  height: 210px;
  position: absolute;
  top: 20%;
  left: calc(50%);
  transform: translateX(-50%);
  padding: 25px;
  border-radius: 5px;
  text-align: center;
  
}

.form_container .manage_tip .title {
  font-family: "Microsoft YaHei";
  font-weight: bold;
  font-size: 26px;
  color: rgb(255,255, 255);
}

.loginForm {
  margin-top: 20px;
  background-color: #fff;
  padding: 40px 40px 20px 20px;
  border-radius: 5px;
  box-shadow: 0px 5px 10px #cccc;
}

.submit_btn {
  width: 100%;
  /* left: 10%; */
transform: translateX(-5%);
}
.tiparea {
  text-align: right;
  font-size: 12px;
  color: #333;
  
}
.tiparea p a {
  color: #409eff;
}
.copyright {
    width: 100%;
	height: 80px;
	line-height: 30px;
	color: #999999;
	font-size: 16px;
	background-color: #222;
	margin: 0px;
    position: absolute;
    bottom: 0;
	
}
.copyright a {
	color: #999999;
	-webkit-transition: all ease 300ms;
	-moz-transition: all ease 300ms;
	-ms-transition: all ease 300ms;
	-o-transition: all ease 300ms;
	transition: all ease 300ms;
}
.copyright a:hover {
	color: #fff;
}

.bgcc{
	background-color: #222222;
	margin-top: 0;
	padding-top: 10px;
	margin-left: -20px;
}
.bgc{
	
	vertical-align: middle;
}
.bgcc p{
	height: 100%;
}
.bgcc p a{
	text-decoration: none;
}
.right{
    float: right;
}
</style>

