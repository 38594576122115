<template>
    <div class="fillContainer">
        <div>
            <el-form :inline="true" ref="add_data" :model='search_data'>
                <el-form-item label="按照时间筛选:">
                    <el-date-picker v-model="search_data.startTime" type="datetime" placeholder="选择开始时间">
                    </el-date-picker>
                    --
                    <el-date-picker v-model="search_data.endTime" type="datetime" placeholder="选择结束时间">
                    </el-date-picker>
                </el-form-item>
                <el-form-item>
                    <el-button type='primary' size="search" @click="handleSearch()">筛选</el-button>
                </el-form-item>
                <el-form-item>
                    <el-button type='primary' size="search" @click="handleAdd()">增加节点</el-button>
                </el-form-item>
                <el-form-item class="btnRight">
                    <el-form-item label="按照节点ID筛选:">
                        <el-input v-model="search_data.id" placeholder="请输入节点ID" style="dispaly:inline"></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button type='primary' size="search" @click="search()">搜索</el-button>
                    </el-form-item>
                </el-form-item>
            </el-form>
        </div>
        <div class="table_container">
            <el-table v-if="tableData.length > 0" :data="tableData" max-height='450' border style="width: 100%">
                <el-table-column type="expand">
                    <template slot-scope="props">
                        <el-form label-position="left" inline class="demo-table-expand">
                            <el-form-item label="节点名称">
                                <span>{{ props.row.name }}</span>
                            </el-form-item>
                            <el-form-item label="GPS">
                                <span>{{ props.row.gps }}</span>
                            </el-form-item>
                            <el-form-item label="硬件版本">
                                <span>{{ props.row.hard_v }}</span>
                            </el-form-item>
                            <el-form-item label="固件版本">
                                <span>{{ props.row.frim_v }}</span>
                            </el-form-item>
                            <el-form-item label="在线时长">
                                <span>{{ props.row.onlinetime}}</span>
                            </el-form-item>
                            <el-form-item label="MQTT服务器">
                                <span>{{ props.row.mqtt_server }}</span>
                            </el-form-item>
                            <el-form-item label="订阅主题">
                                <span>{{ props.row.topic }}</span>
                            </el-form-item>
                        </el-form>
                    </template>
                </el-table-column>
                <el-table-column type='index' label="序号" align='center' width="50">
                </el-table-column>
                <el-table-column prop='registertime' label="创建时间" align='center' width="250">
                    <template slot-scope="scope">
                        <i class="el-icon-time"></i>
                        <span style="margin-left: 10px">{{ scope.row.registertime}}</span>
                    </template>
                </el-table-column>
                <el-table-column prop='Id' label="节点ID" align='center' width="170">
                </el-table-column>
                <el-table-column prop='adminid' label="管理员ID" align='center' width="130">
                </el-table-column>
                <el-table-column prop='gateway_id' label="上级节点ID" align='center' width="170">
                    <template slot-scope="scope">
                        <span>{{ scope.row.gateway_id }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop='online_status' label="在线状态" align='center' width="80">
                    <template slot-scope="scope">
                        <span style="color:red">{{ scope.row.online_status }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop='work_status' label="工作状态" align='center' width="100">
                    <template slot-scope="scope">
                        <span style="color:green">{{ scope.row.work_status}}</span>
                    </template>
                </el-table-column>
                <el-table-column label="操作" prop="operation" fixed="right" align="center" width="320">
                    <template slot-scope="scope">
                        <el-button type='warning' icon='edit' size="small" @click="handleEdit(scope.$index, scope.row)">
                            编辑</el-button>
                        <el-button size="small" icon='delete' type="danger"
                            @click="handleDelete(scope.$index, scope.row)">删除</el-button>
                        <el-button size="small" type="primary"
                            @click="operationOpen(scope.$index, scope.row)">开</el-button>
                        <el-button size="small" type="primary"
                            @click="operationClose(scope.$index, scope.row)">关</el-button>
                        <el-button size="small" type="primary" @click="getinit(scope.$index, scope.row)">连接</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-row>
                <el-col :span='24'>
                    <div class="pagination">
                        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                            :current-page.sync="paginations.page_index" :page-sizes="paginations.page_sizes"
                            :page-size="paginations.page_size" :layout="paginations.layout" :total="paginations.total">
                        </el-pagination>
                    </div>
                </el-col>
            </el-row>
        </div>
        <Dialog :dialog="dialog" :formData="formData" @update='getProfile'></Dialog>
    </div>
</template>

<script>
import Dialog from '../components/Dialognode'

export default {
    name: 'fundList',
    data() {
        return {
            search_data: {
                startTime: '',
                endTime: '',
                id: ''
            },
            paginations: {
                page_index: 1, //当前位于多少页
                total: 0, //总数
                page_size: 5,  //一页显示多少条
                page_sizes: [5, 10, 15], //每页显示多少条
                layout: 'total,sizes,prev,pager,next,jumper' // 翻页属性
            },
            tableData: [],
            allTableData: [],
            fileterTableData: [],
            formData: {
                registertime: '',
                id: '',
                adminid: '',
                gateway_id: '',
                online_status: '',
                work_status: '',
                frim_v: '',
                hard_v: '',
                mqtt_server: '',
                topic: ''
            },
            dialog: {
                show: false,
                title: '',
                option: 'edit'
            },
            Addinformation: { nodeid: '' }
        }
    },
    methods: {
        getProfile() {
            //首先获取信息
            this.$axios.get({
                url: "/my/node/nodelist",
            })
                .then(res => {
                    this.allTableData = res.data.data
                    this.fileterTableData = res.data.data
                    //设置分页数据
                    this.setPaginations()
                })
                .catch(err => console.log(err))
        },
        gethandshake(index, row) {
            this.$axios.get({ url: `/my/operation/handshake/${row.Id}`, data: this.Id }).then(() => {
                this.$message('握手成功')
                this.getProfile()
            }).catch(error => console.log(error))
        },
        getinit(index, row) {
            this.$axios.get({ url: `/my/operation/init/${row.Id}`, data: this.Id }).then(() => {
                this.$message('连接成功')
                this.getProfile()
            }).catch(error => console.log(error))
        },
        handleEdit(index, row) {
            this.dialog = {
                show: true,
                title: '编辑节点信息',
                option: 'exit'
            }
            this.formData = {
                adminid: row.adminid,
                name: row.name,
                gateway_id: row.gateway_id,
                nodeid: row.Id,
                mqtt_server: row.mqtt_server,
                topic: row.topic
            }
        },
        handleDelete(index, row) {
            this.$axios.get({ url: `/my/node/delete/${row.Id}`, data: this.Id }).then(() => {
                this.$message('删除成功')
                this.getProfile()
            }).catch(error => console.log(error))
        },
        operationOpen(index, row) {
            this.$axios.get({ url: `/my/operation/open/${row.Id}`, data: this.Id }).then(() => {
                this.$message('打开成功')
                this.getProfile()
            }).catch(error => console.log(error))

        },
        operationClose(index, row) {
            this.$axios.get({ url: `/my/operation/close/${row.Id}`, data: this.Id }).then(() => {
                this.$message('关闭成功')
                this.getProfile()
            }).catch(error => console.log(error))

        },
        setPaginations() {
            this.paginations.total = this.allTableData.length
            this.paginations.page_index = 1
            this.paginations.page_size = 5
            //设置默认分页数据
            this.tableData = this.allTableData.filter((item, index) => {
                return index < this.paginations.page_size
            })
        },
        handleSizeChange(page_size) {
            this.paginations.page_index = 1
            this.paginations.page_size = page_size
            this.tableData = this.allTableData.filter((item, index) => {
                return index < page_size
            })
        },
        handleCurrentChange(page) {
            //获取当前页
            let index = this.paginations.page_size * (page - 1)
            //数据总数
            let nums = this.paginations.page_size * page
            let tables = []
            for (let i = index; i < nums; i++) {
                if (this.allTableData[i]) {
                    tables.push(this.allTableData[i])
                }
                this.tableData = tables
            }
        },
        handleSearch() {
            if (!this.search_data.startTime || !this.search_data.endTime) {
                this.$message({
                    type: 'warning',
                    message: '请选择时间区间'
                })
                this.getProfile()
                return
            }
            const sTime = this.search_data.startTime.getTime()
            const eTime = this.search_data.endTime.getTime()
            this.allTableData = this.fileterTableData.filter(item => {
                let date = new Date(item.registertime)
                let time = date.getTime()
                console.log(time)
                return time >= sTime && time <= eTime//在时间区间
            })
            this.setPaginations()
        },
        handleAdd() {
            this.$prompt('请输入节点MAC地址', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
            }).then(({ value }) => {
                this.$message({
                    type: 'success',
                    message: '新节点MAC地址: ' + value
                });
                this.Addinformation = {
                    nodeid: value
                }
                this.$axios.post({ url: `/my/node/addnode`, data: this.Addinformation }).then(() => {
                    this.$message('已添加新节点')
                    this.setPaginations()
                }).catch(error => console.log(error))
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '取消输入'
                });
            });


        },
        search() {
            if (!this.search_data.id) {
                this.getProfile()
                this.$message({
                    type: 'warning',
                    message: '请输入查询节点ID'
                })
                return
            }
            this.allTableData = this.fileterTableData.filter(item => {
                return item.Id == this.search_data.id
            })
            this.setPaginations()
        }
    },
    computed: {
        user() {
            return this.$store.getters.user
        }
    },
    created() {
        this.getProfile()
    },
    components: {
        Dialog,
    }
}
</script>

<style scoped>
.fillContainer {
    width: 100%;
    height: 100%;
    padding: 16px;
    box-sizing: border-box
}

.btnRight {
    float: right;
}

.textRirht {
    float: right;
}

.pagination {
    text-align: right;
    margin-top: 10px;
}

.demo-table-expand {
    font-size: 0;
}

.demo-table-expand label {
    width: 90px;
    color: #99a9bf;
}

.demo-table-expand .el-form-item {
    position: relative;
    left: 50px;
    margin-right: 0;
    margin-bottom: 0;
    width: 100%;
}

.el-form-item__label {
    width: 180px;
}
</style>
 