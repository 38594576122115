<template>
    <div class="dialog">
        <el-dialog
        :title="dialog.title"
        :visible.sync="dialog.show"
        :close-on-click-modal="false"
        :modal-append-to-body="false"
        >
            <div class="form">
                <el-form ref="form" :model="formData" :rules="form_rules" label-width='120px'
                style="margin:10px;width:auto">
                    <el-form-item prop="describe" label="节点名称:">
                        <el-input type='describe' v-model="formData.name"></el-input>
                    </el-form-item>
                    <el-form-item prop="income" label="管理员ID:">
                        <el-input type='income' v-model="formData.adminid"></el-input>
                    </el-form-item>
                    <el-form-item prop="cash" label="上级节点ID:">
                        <el-input type='cash' v-model="formData.gateway_id"></el-input>
                    </el-form-item>
                    <el-form-item prop="cash" label="MQTT服务器:">
                        <el-input type='cash' v-model="formData.mqtt_server"></el-input>
                    </el-form-item>
                    <el-form-item prop="cash" label="订阅主题:">
                        <el-input type='cash' v-model="formData.topic"></el-input>
                    </el-form-item>
                    <el-form-item class="text_right">
                        <el-button @click="dialog.show=false">取消</el-button>
                        <el-button type='primary' @click="onSubmit('form')">提交</el-button>
                    </el-form-item>
                </el-form>
            </div>
        </el-dialog>
    </div>
</template>

<script>
export default {
    props:{
        dialog:Object,
        formData:Object
    },
    data(){
        return {
            form_rules:{
                name:[
                    {required:true,message:'节点名称不能为空!',trigger:'blur'}
                ],
                adminid:[
                    {required:true,message:'管理员ID不能为空!',trigger:'blur'}
                ],
            }
        }
    },
    methods:{
        onSubmit(form){
            this.$refs[form].validate(valid=>{
                if(valid){
                    const urll = `update/${this.formData.userid}`
                    this.$axios.post({url:`/my/node/${urll}`,data:this.formData})
                    .then(()=>{
                        this.$message({
                            message:'数据修改成功',
                            type:'success'
                        })
                    })
                    //隐藏对话框
                    this.dialog.show=false
                    this.$emit('update')
                }
            })
        }
    }
}
</script>
