<template>
    <div class="index">
        <HeadNav></HeadNav>
        <LeftMenu></LeftMenu>
        <Dialog></Dialog>
        <div class="rightContainer">
            <router-view></router-view>
        </div>
    </div>
</template>

<script>
import HeadNav from '../components/HeadNav.vue';
import LeftMenu from '../components/LeftMean.vue'
import Dialog from '../components/Dialoguser.vue'
    export default{
        name:'index',
        components:{
            HeadNav,//注册之后可以在上面直接调用
            LeftMenu,
            Dialog,
        }
    };
</script>
<style scoped>
.index{
  position: fixed;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.rightContainer{
  position: relative;
  top:0;
  left: 180px;
  width: calc(100% - 180px);
  height: calc(100% - 71px);
  overflow: auto;
}
</style>
