<template>
    <div class="login">      
        <section class="form_container">
            <div class="manage_tip">
                <span class="title">重置密码</span>
                <el-form :model="checkUser" :rules="rules" ref="forgetForm" label-width="60px" class="loginForm">
                     <el-form-item label="账号" prop="UserId">
                        <el-input v-model="checkUser.UserId" placeholder="请输入账号"></el-input>
                    </el-form-item>
                    <el-form-item label="手机" prop="Phone">
                        <el-input v-model="checkUser.Phone" placeholder="请输入联系方式"></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button type='primary' class="submit_btn" @click="submitForm('forgetForm')">确定</el-button>
                    </el-form-item>
                    <el-link class="right" @click="login">返回</el-link>
                </el-form>
            </div>
        </section>
        <div class="copyright">
				  <div class="bgc" align="center" style="color: #ffffff;">
							<div class="bgcc">
								<p style=" max-width: 1600px;">开封市淳阳科技有限责任公司 ©[PURESUN]&nbsp;&nbsp;2018-{{ nowDate }}</p>
								<!-- <p style=" max-width: 1600px;">豫ICP备16018699&nbsp;&nbsp;&nbsp;<a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=41010802002001"><img src="bnico.png">&nbsp;豫公网安备 41010802002001号</a></p> -->
							</div>
				 </div>
			 </div> 
    </div>
</template>

<script>

export default {
    name:'forget',
    data(){
        return{
            nowDate: "",
            checkUser:{
                UserId:'',
                Phone:'',
            },
            rules:{
                UserId:[
                    {required:true,message:'账号不能为空',trigger:'blur'},
                    {min:6,max:11,message:'长度应在6-11之间',trigger:'blur'}
                ],
                Phone:[
                    {required:true,message:'联系方式不能为空',trigger:'blur'},
                    {min:11,max:11,message:'长度应该为11',trigger:'blur'}
                ],
            }
        }
    },
    methods:{
        formData(paramObj) {
        if (!paramObj) {
            paramObj = {};
        }
        let data = new FormData();
        Object.keys(paramObj).forEach((key) => data.append(key, paramObj[key]));
        return data;
    },
        submitForm(formName){
            this.$confirm('确定重置密码?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                    }).then(() => {
                    this.$refs[formName].validate((valid) => {
                            if (valid) {
                this.$axios.post({
                    url:"/api/forget",
                    data:this.checkUser,
                   }).then(res=>{
                            if(res.data.data==true)
                               {
                                   this.$message({
                                    type: 'success',
                                    message: '重置成功，默认密码为123456'
                                    });
                                this.$router.push('/login')
                    }
                            else
                                this.$router.push('/login')
                        })
                        }    
                    })
                    }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消'
                    });          
                    });
        },
        login(){
            this.$router.push('/login')
        },
        isEmpty(value){
            return (
                value === undefined ||
                value === null ||
                (typeof value === 'object' && Object.keys(value).length===0) ||
                (typeof value === 'string' && value.trim().length===0)
            );
        },
        currentTime() {
      setInterval(this.formatDate, 500);
    },
    formatDate() {
      let date = new Date();
      let year = date.getFullYear(); // 年
      this.nowDate = `${year}`;
        }
    },
     mounted() {
    this.currentTime();
  },
}
</script>

<style scoped>
.login {
  position: fixed;
  width: 100%;
  height: 100%;
  background: url(../assets/clock.jpeg) no-repeat center center;
  background-size: 100% 100%;
}
.form_container {
  width: 370px;
  height: 210px;
  position: absolute;
  top: 20%;
  left: calc(50%);
  transform: translateX(-50%);
  padding: 25px;
  border-radius: 5px;
  text-align: center;
  
}

.form_container .manage_tip .title {
  font-family: "Microsoft YaHei";
  font-weight: bold;
  font-size: 26px;
  color: rgb(255,255, 255);
}

.loginForm {
  margin-top: 20px;
  background-color: #fff;
  padding: 40px 40px 20px 20px;
  border-radius: 5px;
  box-shadow: 0px 5px 10px #cccc;
}

.submit_btn {
  width: 100%;
  /* left: 10%; */
transform: translateX(-5%);
}
.tiparea {
  text-align: right;
  font-size: 12px;
  color: #333;
  
}
.tiparea p a {
  color: #409eff;
}
.copyright {
    width: 100%;
	height: 80px;
	line-height: 30px;
	color: #999999;
	font-size: 16px;
	background-color: #222;
	margin: 0px;
    position: absolute;
    bottom: 0;
	
}
.copyright a {
	color: #999999;
	-webkit-transition: all ease 300ms;
	-moz-transition: all ease 300ms;
	-ms-transition: all ease 300ms;
	-o-transition: all ease 300ms;
	transition: all ease 300ms;
}
.copyright a:hover {
	color: #fff;
}

.bgcc{
	background-color: #222222;
	margin-top: 0;
	padding-top: 10px;
	margin-left: -20px;
}
.bgc{
	
	vertical-align: middle;
}
.bgcc p{
	height: 100%;
}
.bgcc p a{
	text-decoration: none;
}
.right{
    float: right;
}
</style>

